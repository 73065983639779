@font-face {
  font-family: 'MyWebFont';
  src: url('../../assets/fonts/Poppins/Poppins-SemiBold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'MyWebFont';
  src: url('../../assets/fonts/Poppins/Poppins-Light.ttf'); /* IE9 Compat Modes */
}



body{
  background: #F7E7D0 ;
}


   .loader-cls{
    text-align: center;
    position: absolute;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
   }

   .notify{
    /* position: relative;
    top: 80%; */
    font-size: 18px;
    color: #212529;
    font-family: MyWebFont;
    /* margin-left: 4rem; */
    
   }

   .notify-btn{
   font-family: 'MyWebFont';
   font-size: 20px;
   border-radius: 23px;
   padding:10px 20px 10px 20px;
   font-weight: 600;
   border:0; 
   margin-bottom: 25px;
  }
   .notify-btn:hover{
     background: #000;
     color: #fff;
     
   }




   #waitingscreen{
    /* height:530px; */
     position: relative;
     min-height: 65vh;
  }

  .timerwrapper{
    /* padding-left:4em; */
    text-align: center;
    max-width: 550px;
    /* margin: auto; */
    padding:20px ;
    margin-top: 30px;
    background: #fff;
    border-radius: 15px;
    box-shadow:0 5px 30px rgb(32 32 32 / 25%) ;
  }

  .wait-timer{
    /* position: absolute; */
    /* top: 80%;
    left: 20%;
    display: flex;
    justify-content: space-between;
  */
  border-radius: 11px;
  /* border: 4px solid; */
  margin-top: 10px;
  margin-bottom: 10px;
  }

  table{
    /* border-radius: 9px; */
      background: #fff;
      /* border-radius: 11px; */
      /* border: 4px solid; */
      margin:  auto;   
       border-radius: 6px;
      width: 100%;
  }
 
  tr#clockdiv td{
    /* padding: 9px 30px 30px 30px;     */
    font-size: 16px;
    font-weight: bold;
    /* max-width: 62px; */
    text-align: left;
    padding-left: 30px;
}

tr#clk-div td p{
  margin: 0;

}
tr#clk-div td{
/* padding: 5px 5px 5px 50px; */
font-size: 60px;
font-weight: bold;
text-align: center;
vertical-align: bottom;
line-height: 65px;
}
tr#clockdiv td:last-child{
  padding-left: 40px;
}
.textWrapper{
/* position: relative;
    top: 35%; */
}

.textWrapper p{
   font-size: 25px;
   font-family: MyWebFont; 
   margin: 0;

}

.wait-screen-wrapper img{
  max-width: 100%;
  /* padding: 20px; */
}


.bgimg{
  
  /* background-image: url(../../assets/images/bg-image.png); */
  background-repeat: no-repeat;
  background-position: bottom right;
  background-position-x: left;
  background-position-y: 0px;
}

.textWrapper p:first-child {
  color: #000;
  font-size: 2.5em;
  font-family: MyWebFont;
  margin: 0;
  }

.textWrapper p:nth-child(2) {
  /* margin-top: 58px; */
  font-size: 25px;
  /* margin-left: 50px; */
}


.bgimg-sesssion{
  height:100vh;
  background-image: url(../../assets/images/session-img.png);
  background-repeat: no-repeat;
  background-position:center;
  background-position-x: center;
}
.bgimg-thanks{
  height:100vh;
  background-repeat: no-repeat;
  background-position:center;
  background-position-x: center;
}

.session-screen-wrapper{
  position: absolute;
    z-index: 999;
    background: #F7E7D0;
    top: 0;
    right: 0;
     left: 0;
}
.userwait .session-screen-wrapper .thankstxt{
  background: transparent;
  min-height: 50vh;
  box-shadow: none;
  color: #fff;
  font-size: 27px;
  top:78px
}
#sessiontxt{
  position: fixed;
  width: 50%;
  height: 50px;
  top: 62%;
  left: 37%;
  margin-top: -4px;
  font-size: 25px;
  color: #fff;
}
.m-h100{
  min-height: 100vh;
}
.thankstxt{
  font-size: 25px;
  color: #000;
  background: #fff;
  padding: 10px; 
  border-radius: 15px;
  box-shadow:0 5px 30px rgb(32 32 32 / 25%) ;
}
.thankstxt_wait{
  font-size: 25px;
  color: #000;
}
.thankstxt h5{
  text-align: left;
  font-weight: 600;
  padding-top: 10px;
  font-size: 24px;
}

.thankstxt .logo{
  max-width: 100%;
}
.thankstxt h6{
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  color: #969998;
}
@media only screen and (max-width: 600px) {
 
  #sessiontxt{
    left: 0%;
    width: 100%;
    text-align: center;
  }
  #thankstxt{
    left: 0%;
    width: 100%;
    text-align: center;
    color: #000;
  }

  .thankstxt h5{ 
    padding-top: 0px;
    font-size: 20px;
  }

  .thankstxt p{ 
    padding-top: 0px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

  .textWrapper p:first-child {
    font-size: 2.0em;   
}

.textWrapper p:nth-child(2) {
  
  margin-top: 0;
  font-size: 25px;
  margin-left: 0;
  
}

  .bgimg {
    
    background-repeat: no-repeat;
    background-position: bottom right;
    background-position-x: 43%;
    background-position-y: 77%;
    background-size: 62%;
    z-index: 99;
    height: 100%;
    
}

  .wait-timer {
    /* left: 3% !important;  */
    z-index: 99;
    top:150%;
}

table {
   /* width: 40% !important;; */
}

tr#clk-div td {
  /* padding: 3px 3px 3px 30px; */
  font-size: 45px;
  
}

tr#clockdiv td {
  padding: 6px !important;
  text-align: center;  
}
	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	/* tr { border: 1px solid #ccc; } */
	
	td { 
		/* Behave  like a "row" */
		border: none;
		/* border-bottom: 1px solid #eee;  */
		position: relative;
		/* padding-left: 50%;  */
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/

}
.logo_wait{
  padding:5px;
  max-width: 150px!important;
}

@media only screen and (min-width: 767px) and (max-width: 1025px) {
  #waitingscreen{
    padding-top: 15%;
  }
  .thankstxt .col-md-5{
    max-width: 65%;
    flex: 0 0 65.666667%;
  }
  .volumesec{
    right: 31%;
  }
}

@media only screen and (max-width: 468px) {
  .timerwrapper{
    max-width: 350px;
  }
  .full{
    background: #F7E7D0!important;
    background-size: cover;
  }
}
 
@media only screen and (max-width: 1024px) {
   .full{
    background: #F7E7D0!important;
    background-size: cover;
  }
}
@media only screen and (min-width: 350px) and (max-width: 1025px) {
  tr#clockdiv td{
    /* padding: 9px 30px 30px 30px;     */
    font-size: 15px;
  }
  
}
.full.videocall{
  /* background: url('../../assets/images/waitingbg.PNG'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f7e6d0;
}

.cameracontrol{
  display:none;
}

@media only screen and  (max-width: 500px) {

  .cameracontrol{
    display:flex;
  }
  
  .ag-btn-group{
    width: 97%;
    bottom:-92px;
    left: 10px;
  }
  .volumesec{
    right: 20%;
  } 
  
#ag-canvas .audiolistsec{
  bottom: 10px;
 }
#ag-canvas .audiolistsec{
right: 0;}
 .ag-header{
   height: 50px;
 }
}

@media only screen and (min-width: 520px) and (max-width: 900px) {
  .userwait #ag-canvas{
    width: 100%!important;
  }
  .session-screen-wrapper{
    top:50vh!important;
  }
  .thankstxt .logo{
    max-width: 300px;
  }
}